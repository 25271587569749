<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />

    <main class="grow">
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>

      <section class="relative">
        <div class="max-w-6xl mx-auto px-4 sm:px-6 relative">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <h1 class="h1 mb-4" data-aos="fade-up">Comment puis-je vous aider ?</h1>
              <p class="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
                Contactez-moi via ce formulaire et je vous répondrai aussi vite que possible. Vous pouvez également me contacter via mes réseaux sociaux.
              </p>

              <ul class="flex justify-center mt-4">
                <li>
                  <a href="https://github.com/cercxtrova" target="_blank" class="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Github">
                    <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                    </svg>
                  </a>
                </li>
                <li class="ml-4">
                  <a href="https://www.linkedin.com/in/robin-hernie/" target="_blank" class="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Linkedin">
                    <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <form class="max-w-xl mx-auto" @submit.prevent="submitForm">
              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="first-name">Prénom <span class="text-red-600">*</span></label>
                  <input v-model="message.firstname" id="first-name" type="text" class="form-input w-full text-gray-300" placeholder="Prénom" required />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="last-name">Nom <span class="text-red-600">*</span></label>
                  <input v-model="message.lastname" id="last-name" type="text" class="form-input w-full text-gray-300" placeholder="Nom" required />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-4">
                 <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="email">Email <span class="text-red-600">*</span></label>
                  <input v-model="message.email" id="email" type="email" class="form-input w-full text-gray-300" placeholder="Email" required />
                </div>
                 <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="phone">Numéro de téléphone <span class="text-red-600">*</span></label>
                  <input v-model="message.phone" id="phone" type="phone" class="form-input w-full text-gray-300" placeholder="Numéro de téléphone" required />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full px-3">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="subject">Sujet <span class="text-red-600">*</span></label>
                  <input v-model="message.subject" id="subject" type="text" class="form-input w-full text-gray-300" placeholder="Comment puis-je vous aider ?" required />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full px-3">
                  <label class="block text-gray-300 text-sm font-medium mb-1" for="message">Message</label>
                  <textarea v-model="message.content" id="message" rows="4" class="form-textarea w-full text-gray-300" placeholder="Écrivez votre message"></textarea>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3">
                  <button class="btn btn-primary w-full disabled:opacity-75" type="submit" :disabled="loading">
                    <svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {{ submitValue }}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </section>
    </main>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import PageIllustration from '@/components/PageIllustrationComponent.vue'
import Footer from '@/components/FooterComponent.vue'

import { useMeta } from 'vue-meta'

export default {
  name: 'ContactPage',
  setup () {
    useMeta({
      title: 'Me contacter',
      description: "Contactez un développeur web full stack Ruby on Rails pour discuter de votre projet et garantir son succès."
    })
  },
  components: {
    Header,
    PageIllustration,
    Footer,
  },
  data () {
    return {
      loading: false,
      submitValue: 'Envoyer',
      message: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        subject: null,
        content: null
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      this.$axios.post('/create_message', this.message).then(() => {
        this.loading = false
        this.submitValue = 'Message reçu, merci !'
        setTimeout(() => {
          Object.assign(this.$data, this.$options.data())
        }, 3000)
      }).catch(() => {
        this.loading = false
        this.submitValue = "Oops, quelque chose s'est mal passé. Réessayez."
        setTimeout(() => {
          this.submitValue = 'Envoyer'
        }, 3000)
      })
    }
  }
};
</script>
