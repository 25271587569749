<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="grow">
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>
      <section class="relative">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 md:pt-40">
            <div class="max-w-4xl pb-12 text-center md:text-left">
              <h1 class="h1" data-aos="fade-up">Ils m'ont fait confiance, et sont les mieux placés pour en parler.</h1>
            </div>
          </div>
        </div>
      </section>
      <Testimonials :testimonials="testimonials" />
      <Cta />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import PageIllustration from '@/components/PageIllustrationComponent.vue'
import Testimonials from '@/components/TestimonialsComponent.vue'
import Cta from '@/components/CtaComponent.vue'
import Footer from '@/components/FooterComponent.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'RecommendationPage',
  setup () {
    useMeta({
      title: 'Avis & Recommandations',
      description: "J'ai eu la chance de travailler à leurs côtés en tant que développeur web full stack Ruby on Rails et Vue.js. Ce sont les mieux placés pour en parler !"
    })
  },
  components: {
    Header,
    PageIllustration,
    Testimonials,
    Cta,
    Footer
  },
  data () {
    return {
      testimonials: [
        {
          name: 'Cyril Suzat',
          content: 'Robin is a great developer. Passionate, curious and hard worker, I highly recommend him.',
          image: 'cyril',
          job: 'CTO @ Veepee.ch',
          url: 'https://www.linkedin.com/in/robin-hernie/'
        },
        {
          name: 'Brice Texier',
          content: "J'ai apprécié de bosser avec Robin car il est efficace, discret et agréable. La collaboration s'est fait naturellement grâce à son ouverture d'esprit qui vient de ses multiples compétences et centres d'intérêts.",
          image: 'brice',
          job: 'CTO @ Codeur',
          url: 'https://www.linkedin.com/in/robin-hernie/'
        },
        {
          name: 'Patrick Herter',
          content: "Robin a excellemment réalisé sa mission, avec expertise, rigueur et organisation, et une capacité d'intégration qui a contribué à la réussite collective.",
          image: 'patrick',
          job: 'CEO @ FRN Ingénierie',
          url: 'https://www.malt.fr/profile/robinhernie'
        },
        {
          name: 'Alan Robis',
          content: "Robin a su me conseiller et me donner son avis sur diverses stratégies de paiement. Il a intégré le 3D Secure de Stripe en réorganisant le code en corrigeant les bugs. Mise en place d'une conversation groupée. Établir une notice pour les futurs Developpeurs. Très professionnel. Je recommande fortement.",
          image: 'alan',
          job: 'CEO @ Chat Event',
          url: 'https://www.malt.fr/profile/robinhernie'
        },
        {
          name: 'Jonathan Sclavon',
          content: "Je ne vais pas partir sur de longues phrases inutiles et sans aucun sens : Robin n'est pas seulement un dev qui va réaliser à la perfection votre cahier des charges, il va s'investir et sait être force de proposition pour améliorer le projet. Je le recommande à 100%.",
          image: 'jonathan',
          job: 'FullStack Developer @ VSI INNOVATION',
          url: 'https://www.malt.fr/profile/robinhernie'
        },
        {
          name: 'Walid Azza',
          content: "Robin est réellement passionné par le digital. Il prend le temps de bien comprendre le besoin du client tout en s'acculturant au domaine d'activité de ce dernier. C'est vraiment un plus. Je recommande vivement Robin aussi bien pour son savoir-faire que pour son savoir-être. Au plaisir de retravailler avec toi !",
          image: 'walid',
          job: 'Chef de projet RUN/TMA @ Insitaction',
          url: 'https://www.malt.fr/profile/robinhernie'
        },
        {
          name: 'Sylvie Brély',
          content: "Bonne écoute et ouverture pour rechercher des solutions ! Rapidité et gentillesse. A recommander.",
          image: 'sylvie',
          job: 'Fondatrice @ La Nouvelle Athènes',
          url: 'https://www.malt.fr/profile/robinhernie'
        },
        {
          name: 'Faustine Hernie',
          content: "Robin est passionné et bienveillant, il est à l'écoute, prend en compte les besoins spécifiques de ses clients afin de leur proposer un projet personnalisé et pertinent.  Je lui fais entièrement confiance et le recommande !",
          image: 'faustine',
          job: 'Lectrice-Correctrice Rewriter @ Le temps des lettres',
          url: 'https://www.malt.fr/profile/robinhernie'
        }
      ]
    }
  }
};
</script>
