<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ `${content} | Robin Hernie` }}</template>
  </metainfo>
  <router-view />
</template>

<script>
import AOS from 'aos'
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({
      title: 'Développeur Web Full Stack Freelance – Ruby on Rails',
      description: 'Robin Hernie, développeur web full stack freelance – Ruby on Rails.'
    })
  },
  mounted() {
    setTimeout(() => {
      AOS.init({
        once: true,
        duration: 600,
        easing: 'ease-out-sine'
      })
    }, 1)
  }
}
</script>
