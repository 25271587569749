<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 border-gray-800" :class="{ 'border-t md:py-20': withTitle }">
        <div v-if="withTitle" class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4" data-aos="fade-up" data-aos-delay="200">Avis & recommandations</h2>
          <p class="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="400">
            Ils m'ont fait confiance, et j'ai eu la chance de travailler à leurs côtés. Ce sont les mieux placés pour en parler !
          </p>
        </div>

        <div class="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none" data-aos-id-testimonial>
          <div v-for="(testimonial, index) in testimonials" :key="testimonial.name" class="flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" :data-aos-delay="`${index*2}00`" data-aos-anchor="[data-aos-id-testimonial]">
            <div>
              <div class="relative inline-flex flex-col mb-4">
                <img class="rounded-full" :src="require(`@/assets/images/testimonials/${testimonial.image}.jpeg`)" width="48" height="48" :alt="testimonial.name" />
                <svg class="absolute top-0 right-0 -mr-3 w-6 h-5 fill-current text-purple-600" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
                </svg>
              </div>
            </div>
            <blockquote class="text-lg text-gray-400 grow">— {{ testimonial.content }}</blockquote>
            <div class="text-gray-700 font-medium mt-6 pt-5 border-t border-gray-700">
              <cite class="text-gray-200 not-italic">{{ testimonial.name }}</cite> – <a class="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" :href="testimonial.url" target="_blank">{{ testimonial.job }}</a>
            </div>
          </div>
        </div>

        <div v-if="withTitle" class="text-center mt-8" data-aos="fade-up" data-aos-delay="200">
          <router-link to="/recommandations" class="btn btn-secondary w-full sm:w-auto">Lire tous les avis</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TestimonialsComponent',
  props: {
    withTitle: {
      type: Boolean,
      default: false
    },
    testimonials: {
      type: Array,
      required: true
    }
  }
}
</script>
