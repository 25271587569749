<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="grow">
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>
      <PortfolioList />
      <Cta />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import PageIllustration from '@/components/PageIllustrationComponent.vue'
import PortfolioList from '@/components/PortfolioListComponent.vue'
import Cta from '@/components/CtaComponent.vue'
import Footer from '@/components/FooterComponent.vue'

import { useMeta } from 'vue-meta'

export default {
  name: 'PortfolioPage',
  setup () {
    useMeta({
      title: 'Portfolio',
      description: "Après avoir travaillé au sein d'entreprises leader dans leur domaine, je mets désormais toute mon expertise Ruby on Rails au service de mes clients."
    })
  },
  components: {
    Header,
    PageIllustration,
    PortfolioList,
    Cta,
    Footer
  },
};
</script>
