import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/HomePage.vue'
import Recommendation from './pages/RecommendationPage.vue'
import Portfolio from './pages/PortfolioPage.vue'
import About from './pages/AboutPage.vue'
import Contact from './pages/ContactPage.vue'
import PageNotFound from './pages/NotFoundPage.vue'

const routerHistory = createWebHistory()

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      window.scroll({ top: 0 })
    } else {
      document.querySelector('html').style.scrollBehavior = 'auto'
      window.scroll({ top: 0 })
      document.querySelector('html').style.scrollBehavior = ''
    }
  },
  history: routerHistory,
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/recommandations',
      component: Recommendation
    },
    {
      path: '/portfolio',
      component: Portfolio
    },
    {
      path: '/a-propos',
      component: About
    },
    {
      path: '/contact',
      component: Contact
    },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound
    }
  ]
})

export default router
