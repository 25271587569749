<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="grow">
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>

      <HeroHome />
      <PortfolioHome />
      <Testimonials withTitle :testimonials="testimonials" />
      <Cta />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import PageIllustration from '@/components/PageIllustrationComponent.vue'
import HeroHome from '@/components/HeroHomeComponent.vue'
import PortfolioHome from '@/components/PortfolioHomeComponent.vue'
import Testimonials from '@/components/TestimonialsComponent.vue'
import Cta from '@/components/CtaComponent.vue'
import Footer from '@/components/FooterComponent.vue'

export default {
  name: 'HomePage',
  components: {
    Header,
    PageIllustration,
    HeroHome,
    PortfolioHome,
    Testimonials,
    Cta,
    Footer,
  },
  data () {
    return {
      testimonials: [
        {
          name: 'Cyril Suzat',
          content: 'Robin is a great developer. Passionate, curious and hard worker, I highly recommend him.',
          image: 'cyril',
          job: 'CTO @ Veepee.ch',
          url: 'https://www.linkedin.com/in/robin-hernie/'
        },
        {
          name: 'Brice Texier',
          content: "J'ai apprécié de bosser avec Robin car il est efficace, discret et agréable. La collaboration s'est fait naturellement grâce à son ouverture d'esprit qui vient de ses multiples compétences et centres d'intérêts.",
          image: 'brice',
          job: 'CTO @ Codeur',
          url: 'https://www.linkedin.com/in/robin-hernie/'
        },
        {
          name: 'Patrick Herter',
          content: "Robin a excellemment réalisé sa mission, avec expertise, rigueur et organisation, et une capacité d'intégration qui a contribué à la réussite collective.",
          image: 'patrick',
          job: 'CEO @ FRN Ingénierie',
          url: 'https://www.malt.fr/profile/robinhernie'
        }
      ]
    }
  }
};
</script>
