<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">

        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4">Il en aura fallu du temps</h2>
          <p class="text-xl text-gray-400">
            La route n'a pas été un long fleuve tranquille, mais à force de travail et de détermination, j'ai fini par y arriver. 🤝
          </p>
        </div>

        <div class="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>
          <div v-for="(item, index) in items" :key="item.title" class="relative py-4 md:py-6 pl-24" data-aos="fade-up" :data-aos-delay="`${index*2}00`" data-aos-anchor="[data-aos-id-timeline]">
            <div class="pl-2">
              <div class="font-architects-daughter text-xl text-purple-600 mb-2">{{ item.subtitle }}</div>
              <div class="flex items-center mb-3">
                <div class="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">{{ item.year }}</div>
                <div v-if="items.length - 1 !== index" class="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                <div class="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
                <h4 class="h4">{{ item.title }}</h4>
              </div>
              <p class="text-lg text-gray-400">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineComponent',
  data () {
    return {
      items: [
        {
          year: '1994',
          subtitle: 'La Menace Fantôme',
          title: 'Je née en 1994',
          description: "Pile poil quand le grand public découvre internet en France. Je grandis au son du modem 56k et au milieu du bureau rempli de bordel informatique de mon père."
        },
        {
          year: '2007',
          subtitle: 'Le Réveil de la Force',
          title: 'Je découvre la programmation',
          description: "À 13 ans, mes parents m'offrent la PlayStation Portable. Je découvre qu'il est possible de la hack et de lancer du code non signé par Sony. J'apprends le langage LUA sur le tas et commence à créer mes propres jeux."
        },
        {
          year: '2010',
          subtitle: "L'Ascension de Skywalker. Euh, quoi ?",
          title: "Franchement, c'était un désastre",
          description: "Cliché du geek  – et c'était loin d'être cool à l'époque –, je passe tout mon temps sur les ordinateurs et quitte l'école à 16 ans. Entre temps, je m'essaie au C/C++, puis découvre le développement web et apprends HTML/CSS, Javascript, un peu de PHP... sans imaginer en faire mon métier un jour."
        },
        {
          year: '2017',
          subtitle: 'Un nouvel espoir',
          title: "Fini de rigoler mon p'tit pote",
          description: "Après avoir travaillé comme cuisinier, vendeur, magasinier, livreur, photographe indépendant... et avoir passé un DAEU pour obtenir le BAC, on me parle d'une formation pour devenir développeur web. Je saisis l'opportunité et me reprends de passion pour le développement. J'y tombe amoureux de Ruby on Rails, et après 2 stages en simultanés, je décroche mon diplôme."
        },
        {
          year: '2018',
          subtitle: 'Le Retour du Jedi',
          title: "LET'S GOOOO!",
          description: "Je pars 3 mois au Pays de Galles pour améliorer mon anglais et continuer à me former sur internet. À mon retour, je décroche mon premier job de développeur web full stack, en CDI, chez Codeur 🥳. J'y apprends Vue.js, on me donne très vite des responsabilités et me confie le développement de Traduc.com, qui sera lancé quelques mois plus tard."
        },
        {
          year: '2020',
          subtitle: "C'est une bonne situation ça, freelance ?",
          title: "Une nouvelle aventure",
          description: "Après m'être bousillé à continuer à apprendre sur mon temps libre et obtenu un nouveau poste, chez Veepee (anciennement vente-privee.com), en Suisse, je décide de quitter le salariat et de devenir développeur web full stack en freelance. Depuis, vous êtes nombreux à m'avoir fait confiance et je vous en remercie. 🙏"
        }
      ]
    }
  }
}
</script>
