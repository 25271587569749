<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-10 pb-12 md:pt-16 md:pb-20">
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4" data-aos="fade-up" data-aos-id-portfolio-home>Une solide expérience professionnelle</h2>
          <p class="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
            Après avoir travaillé au sein d'entreprises leader dans leur domaine, je mets désormais toute mon expertise au service de mes clients.
          </p>
        </div>

        <div class="max-w-sm mx-auto md:max-w-none">
          <div class="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
            <article class="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-portfolio-home]">
              <header>
                <a href="https://traduc.com" target="_blank" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                    <img class="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src="@/assets/images/traduc.jpg" width="352" height="198" alt="News 01" />
                  </figure>
                </a>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-red-600 hover:bg-red-700 transition duration-150 ease-in-out">Ruby on Rails</span>
                    </li>
                  </ul>
                </div>
                <h3 class="h4 mb-2">
                  <a href="https://traduc.com" target="_blank" class="hover:text-gray-100 transition duration-150 ease-in-out">Traduc.com — Codeur</a>
                </h3>
              </header>
              <p class="text-lg text-gray-400 grow">
                Conception, développement et maintenance de la plateforme Traduc.com — la traduction professionnelle & humaine pour tous vos contenus.
              </p>
            </article>

            <article class="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-portfolio-home]">
              <header>
                <a href="https://hubflo.com" target="_blank" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                    <img class="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src="@/assets/images/hubflo.jpg" width="352" height="198" alt="News 02" />
                  </figure>
                </a>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-red-500 hover:bg-red-600 transition duration-150 ease-in-out">Ruby on Rails</span>
                    </li>
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-yellow-600 hover:bg-yellow-700 transition duration-150 ease-in-out">Hotwire</span>
                    </li>
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-gray-600 hover:bg-gray-700 transition duration-150 ease-in-out">Freelance</span>
                    </li>
                  </ul>
                </div>
                <h3 class="h4 mb-2">
                  <a href="https://hubflo.com" target="_blank" class="hover:text-gray-100 transition duration-150 ease-in-out">Hubflo.com — Hubflo</a>
                </h3>
              </header>
              <p class="text-lg text-gray-400 grow">
                Contribué au développement de Hubflo.com - la plateforme tout-en-un pour bien gérer votre entreprise. Une expérience ultra enrichissante en startup !
              </p>
            </article>

            <article class="flex flex-col h-full" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-portfolio-home]">
              <header>
                <a href="https://www.veepee.ch/fr" target="_blank" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                    <img class="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src="@/assets/images/veepee.jpg" width="352" height="198" alt="News 03" />
                  </figure>
                </a>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-red-600 hover:bg-red-700 transition duration-150 ease-in-out">Ruby on Rails</span>
                    </li>
                  </ul>
                </div>
                <h3 class="h4 mb-2">
                  <a href="https://www.veepee.ch/fr" target="_blank" class="hover:text-gray-100 transition duration-150 ease-in-out">Veepee.ch — Veepee</a>
                </h3>
              </header>
              <p class="text-lg text-gray-400 grow">
                Développeur web full stack au sein de la branche suisse du groupe Veepee (anciennement vente-privee.com) — leader mondial de la vente évènementielle.
              </p>
            </article>
          </div>

          <div class="text-center mt-8" data-aos="fade-up" data-aos-delay="200">
            <router-link to="/portfolio" class="btn btn-secondary w-full sm:w-auto">Découvrir mon portfolio</router-link>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PortfolioHomeComponent'
}
</script>