<template>
  <section class="relative">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <div class="max-w-3xl pb-12 text-center md:text-left">
          <h1 class="h1" data-aos="fade-up">Une solide<br> expérience professionnelle.</h1>
        </div>

        <div class="max-w-sm mx-auto md:max-w-none">
          <div class="grid gap-12 md:grid-cols-3 items-start" data-aos-id-portfolio>

            <article v-for="(job, index) in jobs" :key="job.name" class="flex flex-col h-full" data-aos="fade-up" :data-aos-delay="`${index*2}00`" data-aos-anchor="[data-aos-id-portfolio]">
              <header>
                <a :href="job.url" target="_blank" class="relative block group">
                  <div class="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-1 xl:translate-x-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
                  <figure class="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                    <img class="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" :src="require(`@/assets/images/${job.image}.jpg`)" width="352" height="198" :alt="job.name">
                  </figure>
                </a>
                <div class="mt-5 mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li v-for="badge in job.badges" :key="badge.name" class="m-1">
                      <span class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full transition duration-150 ease-in-out" :class="badge.classes">{{ badge.name }}</span>
                    </li>
                  </ul>
                </div>
                <h3 class="h4 mb-2">
                  <a :href="job.url" target="_blank" class="hover:text-gray-100 transition duration-150 ease-in-out">{{ job.name }}</a>
                </h3>
              </header>
              <p class="text-lg text-gray-400 grow">{{ job.description }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PortfolioListComponent',
  data () {
    return {
      jobs: [
        {
          name: 'Traduc.com',
          description: "Conception, développement et maintenance de la plateforme Traduc.com — la traduction professionnelle & humaine pour tous vos contenus. Projet réalisé en tant que développeur web full stack chez Codeur, qui édite les plateformes Codeur.com, Graphiste.com, Redacteur.com, Facture.net, Traduc.com ainsi que d'autres applications satellites.",
          url: 'https://traduc.com',
          image: 'traduc',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
          ]
        },
        {
          name: 'Hubflo.com',
          description: "Participation au développement de Hubflo.com — la plateforme tout-en-un pour bien gérer votre entreprise. Une expérience ultra enrichissante en startup avec un rythme de développement très soutenu, une équipe ultra compétente, et le tout en utilisant les dernières technologies de l'écosystème Ruby on Rails telles que Turbo et Stimulus (Hotwire). 🚀",
          url: 'https://hubflo.com',
          image: 'hubflo',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
            { name: 'Hotwire', classes: 'bg-yellow-600 hover:bg-yellow-700' },
            { name: 'Freelance', classes: 'bg-gray-600 hover:bg-gray-700' },
          ]
        },
        {
          name: 'Veepee.ch',
          description: "Salarié en tant que développeur web Ruby on Rails full stack au sein de la branche suisse du groupe Veepee (anciennement vente-privee.com) — leader mondial de la vente évènementielle. J'ai ainsi contribué au développement de nouvelles fonctionnalités, maintenance des applications, upgrade de Ruby et Ruby on Rails, optimisation des performances, etc.",
          url: 'https://veepee.ch/',
          image: 'veepee',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
          ]
        },
        {
          name: 'Astrabaz.com',
          description: "Conception d'un module cartographique permettant de suivre l'état des marchés financiers à travers le monde (et c'est très stylé), tout en profitant des services d'alerte, notation, comparaison, sélection et analyse d'Astrabaz, la station de surveillance de marchés dédiée aux acteurs de la finance. J'ai également travaillé sur le développement d'autres fonctionnalités et l'intégration du nouveau design.",
          url: 'https://astrabaz.com/',
          image: 'astrabaz',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
            { name: 'Vue.js', classes: 'bg-green-600 hover:bg-green-700' },
            { name: 'Freelance', classes: 'bg-gray-600 hover:bg-gray-700' },
          ]
        },
        {
          name: 'Codeur.com',
          description: "Développeur web full stack Ruby on Rails & Vue.js. Codeur.com permet aux porteurs de projets de rencontrer les meilleurs freelances francophones. J'ai ainsi contribué au développement de nouvelles fonctionnalités, amélioration du machine learning, migration vers PostgreSQL, amélioration de la stack Javascript, et bien d'autres sujets passionnants.",
          url: 'https://codeur.com/',
          image: 'codeur',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
            { name: 'Hotwire', classes: 'bg-yellow-600 hover:bg-yellow-700' },
            { name: 'Vue.js', classes: 'bg-green-600 hover:bg-green-700' },
          ]
        },
        {
          name: 'Chat Event',
          description: "Chat Event est une application mobile de rencontres pour laquelle j'ai travaillé sur l'API Ruby on Rails. Conseils sur la stratégie de paiement, intégration de Stripe avec paiements off-session, nettoyage du code et correction de bugs, mise en place de conversations de groupe et rédaction d'une documentation pour les futurs développeurs.",
          url: 'https://apps.apple.com/fr/app/chat-event/id1500504645',
          image: 'chatevent',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-500 hover:bg-red-600' },
            { name: 'Stripe', classes: 'bg-violet-500 hover:bg-violet-600' },
            { name: 'Freelance', classes: 'bg-gray-600 hover:bg-gray-700' }
          ]
        },
        {
          name: 'Redacteur.com',
          description: "Développeur web full stack Ruby on Rails pour la plateforme de rédaction de tous vos contenus, grâce à des milliers de rédacteurs freelances web ou copywriters : Redacteur.com ! Maintenance, développement de nouvelles fonctionnalités, amélioration du code historique, Sherlock Holmes de la traque des bugs. 🕵️‍♂️",
          url: 'https://redacteur.com/',
          image: 'redacteur',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
          ]
        },
        {
          name: 'Graphiste.com',
          description: "Développeur web full stack Ruby on Rails pour Graphiste.com. Vous cherchez un graphiste freelance ? Vous y trouverez forcément le graphiste qu'il vous faut pour la création de tous vos travaux graphiques, grâce à une communauté de plus de 10 000 créatifs, pour laquelle j'ai eu la chance de développer de nouveaux outils qui leur rendent la vie plus douce et joyeuse.",
          url: 'https://graphiste.com/',
          image: 'graphiste',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
          ]
        },
        {
          name: 'Facture.net',
          description: "Facture.net est un outil de facturation et devis 100% gratuit, de la société Codeur, sur lequel j'ai travaillé en tant que développeur web full stack Ruby on Rails & Vue.js. Gérez votre fichier client, établissez des devis et générez des factures gratuitement !",
          url: 'https://facture.net/',
          image: 'facture',
          badges: [
            { name: 'Ruby on Rails', classes: 'bg-red-600 hover:bg-red-700' },
            { name: 'Vue.js', classes: 'bg-green-600 hover:bg-green-700' },
          ]
        },
        {
          name: 'Lanouvelleathenes.net',
          description: "Intégration et configuration de Stripe pour l'association La Nouvelle Athènes – Centre des pianos romantique. Intégré à Okast.tv, Stripe leur permet alors la facturation de leur contenu VOD et SVOD avec gestion des paiements uniques, abonnements, remises, fichier client etc.",
          url: 'https://lanouvelleathenes.net/',
          image: 'nouvelleathenes',
          badges: [
            { name: 'Stripe', classes: 'bg-violet-500 hover:bg-violet-600' },
            { name: 'Freelance', classes: 'bg-gray-600 hover:bg-gray-700' }
          ]
        },
        // {
        //   name: 'Chisanabotoru.com',
        //   description: "Chīsana Botoru est ma boutique de gourdes eco-friendly ! Illustrations réalisées par des artistes japonais, aluminium haute qualité certifié SGS, livraison zéro plastique, transport neutre en carbone et encres vegan à base d'eau 100% écocertifiées. 😎",
        //   url: 'https://chisanabotoru.com/',
        //   image: 'chisanabotoru',
        //   badges: [
        //     { name: 'Marketing', classes: 'bg-orange-500 hover:bg-orange-600' },
        //     { name: 'E-commerce', classes: 'bg-amber-500 hover:bg-amber-600' },
        //   ]
        // },
      ]
    }
  }
}
</script>