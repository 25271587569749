<template>
  <section class="relative -mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="max-w-3xl mx-auto">
        <div class="relative w-full h-0 pb-1/2 md:pb-3/4" data-aos-id-about>
          <figure class="absolute h-auto hidden md:block" style="top:45%;width: 41.67%;" data-aos="fade-right" data-aos-anchor="[data-aos-id-about]">
            <img src="@/assets/images/about-mosaic-02.jpeg" style="height:340px;" alt="Mosaique à propos 02" />
          </figure>
          <figure class="relative mx-auto h-auto w-auto" style="width: 78.13%;" data-aos="fade-down" data-aos-delay="100" data-aos-anchor="[data-aos-id-about]">
            <img src="@/assets/images/about-mosaic-03.jpeg" class="mx-auto md:mx-0" style="width:510px;" alt="Mosaique à propos 01" />
          </figure>
          <figure class="absolute h-auto hidden md:block" style="top: 5%;right: 0;" data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-about]">
            <img src="@/assets/images/about-mosaic-01.jpeg" style="height:410px;" alt="Mosaique à propos 03" />
          </figure>
          <figure class="absolute h-auto hidden md:block" style="bottom: 0;right: 5%;" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-about]">
            <img src="@/assets/images/about-mosaic-04.jpeg" style="width:510px;" alt="Mosaique à propos 04" />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutImagesComponent'
}
</script>
