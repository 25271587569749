import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { createMetaManager } from 'vue-meta'

import './assets/css/style.scss'

const app = createApp(App)
app.use(router)
app.use(createMetaManager())
app.mount('#app')

const instance = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:3000/api' : 'https://api.robinhernie.dev/api'
})
app.config.globalProperties.$axios = instance
