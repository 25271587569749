<template>
  <header class="absolute w-full z-30">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-20">
        <nav class="hidden md:flex md:grow">
          <ul class="flex grow flex-wrap items-center">
            <li>
              <router-link to="/" class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">Accueil</router-link>
            </li>
            <li>
              <router-link to="/portfolio" class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">Portfolio</router-link>
            </li>
            <li>
              <router-link to="/recommandations" class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">Avis & recommandations</router-link>
            </li>
            <li>
              <router-link to="/a-propos" class="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">À propos</router-link>
            </li>
          </ul>

          <ul class="flex grow justify-end flex-wrap items-center">
            <li>
              <router-link to="/contact" class="btn-sm btn-primary">Me contacter</router-link>
            </li>
          </ul>
        </nav>

        <div class="md:hidden">
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" rx="1" />
              <rect y="11" width="24" height="2" rx="1" />
              <rect y="18" width="24" height="2" rx="1" />
            </svg>
          </button>

          <nav id="mobile-nav" ref="mobileNav" class="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" :style="[ mobileNavOpen ? { maxHeight: $refs.mobileNav.scrollHeight + 'px', opacity: 1 } : { maxHeight: 0, opacity: .8 } ]">
            <ul class="bg-gray-800 px-4 py-2">
              <li>
                <router-link to="/" class="flex text-gray-300 hover:text-gray-200 py-2">Accueil</router-link>
              </li>
              <li>
                <router-link to="/portfolio" class="flex text-gray-300 hover:text-gray-200 py-2">Portfolio</router-link>
              </li>
              <li>
                <router-link to="/recommandations" class="flex text-gray-300 hover:text-gray-200 py-2">Avis & recommandations</router-link>
              </li>
              <li>
                <router-link to="/a-propos" class="flex text-gray-300 hover:text-gray-200 py-2">À propos</router-link>
              </li>
              <li class="py-4 mt-4 border-t border-gray-700">
                <router-link to="/contact" class="btn btn-primary w-full">
                  Me contacter
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data: function () {
    return {
      mobileNavOpen: false
    }
  },
  methods: {
    clickOutside(e) {
      if (!this.mobileNavOpen || this.$refs.mobileNav.contains(e.target) || this.$refs.hamburger.contains(e.target)) return
      this.mobileNavOpen = false
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return
      this.mobileNavOpen = false
    }    
  },  
  mounted() {
    document.addEventListener('click', this.clickOutside)    
    document.addEventListener('keydown', this.keyPress)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('keydown', this.keyPress)
  }
};
</script>