<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 relative">
      <div class="pt-12 md:pt-20 border-t border-b border-gray-800">
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos-id-cta>
          <h1 class="h2 mb-4" data-aos="fade-up" data-aos-anchor="[data-aos-id-cta]">Prêt à créer de meilleurs produits ?</h1>
          <p class="text-xl text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-cta]">
            Contactez-moi pour discuter de votre projet et mettre en place la meilleure stratégie de développement afin de garantir son succès.
          </p>

          <div class="flex justify-center mb-8" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-cta]">
            <router-link to="/contact" class="btn btn-primary">Me contacter</router-link>
          </div>

          <ul class="flex flex-wrap justify-center text-lg text-gray-400 -mx-2 -my-1">
            <li class="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-cta]">
              <svg class="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>Professionnel & efficace</span>
            </li>
            <li class="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="800" data-aos-anchor="[data-aos-id-cta]">
              <svg class="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>Code haute qualité</span>
            </li>
            <li class="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="1000" data-aos-anchor="[data-aos-id-cta]">
              <svg class="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>Passionné & travailleur</span>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaComponent'
}
</script>