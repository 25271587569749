<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Header />
    <main class="grow">
      <div class="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
        <PageIllustration />
      </div>

      <HeroAbout />
      <AboutImages />
      <Timeline />
      <Cta />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/HeaderComponent.vue'
import PageIllustration from '@/components/PageIllustrationComponent.vue'
import HeroAbout from '@/components/HeroAboutComponent.vue'
import AboutImages from '@/components/AboutImagesComponent.vue'
import Timeline from '@/components/TimelineComponent.vue'
import Cta from '@/components/CtaComponent.vue'
import Footer from '@/components/FooterComponent.vue'

import { useMeta } from 'vue-meta'

export default {
  name: 'AboutPage',
  setup () {
    useMeta({
      title: 'À propos',
      description: "Je m'appelle Robin Hernie, et je suis développeur web full stack spécialisé sur Ruby on Rails. Mais encore ? 🧐"
    })
  },
  components: {
    Header,
    PageIllustration,
    HeroAbout,
    AboutImages,
    Timeline,
    Cta,
    Footer
  },
};
</script>
